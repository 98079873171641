const ResumeHeader = () => (
  <div className="row">
    <div className="col-12 col-md-4" style={{ marginTop: "20px" }}>
      <h2>Andrew Ghaly</h2>
      Lead Software Engineer
    </div>
    <div className="col-12 col-md-8">
      <hr className="d-none d-md-block d-lg-block"></hr>
      <h5 className="d-none d-md-block d-lg-block">Andrew Ghaly</h5>
      Boston, MA
      <br />
      <a href="https://linkedin.com/in/andrewghaly">
        linkedin.com/in/andrewghaly
      </a>
      <br />
      contact@andrewghaly.com
    </div>
  </div>
);

export default ResumeHeader;
