import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Resume from "./components/resume/resume";
import Albers from "./components/albers";
import NotFound from "./components/notFound";
import GameOfThrones from "./components/gameOfThrones";
import Color from "./components/color";
import PasswordGenerator from "./components/passwordGenerator";
import Draw from "./components/draw";

const App = () => (
  <div className="App">
    <Router>
      <Routes>
        <Route exact path="/" element={<Resume />} />
        <Route exact path="/albers" element={<Albers />} />
        <Route exact path="/color" element={<Color />} />
        <Route exact path="/got" element={<GameOfThrones />} />
        <Route exact path="/password" element={<PasswordGenerator />} />
        <Route exact path="/draw" element={<Draw />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  </div>
);

export default App;
