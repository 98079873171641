import { useState } from "react";
import {
  Button,
  InputGroup,
  FormControl,
  Form,
  FormCheck,
} from "react-bootstrap";
const PasswordGenerator = () => {
  let [password, setPassword] = useState("");
  let [withSymbols, setWithSymbols] = useState(true);
  let [withNumbers, setWithNumbers] = useState(true);
  let [withUppercase, setWithUppercase] = useState(true);
  let [numberOfCharacters, setNumberOfCharacters] = useState(12);

  const generatePasswordStyle = {
    padding: "60px",
    backgroundColor: "rgb(234, 234, 234)",
    boxShadow: "rgb(193 193 193) 0px 0px 5px 0px",
  };
  const generateSecurePassword = () => {
    const lowercaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const capitalLetters = lowercaseLetters.toUpperCase();
    const symbols = "!@#$%^&*()?-=;";
    const numbers = "0123456789";
    let allCharacters = lowercaseLetters;
    if (withSymbols) {
      allCharacters += symbols;
    }
    if (withNumbers) {
      allCharacters += numbers;
    }
    if (withUppercase) {
      allCharacters += capitalLetters;
    }
    allCharacters = allCharacters.split("");

    let password = "";
    for (let i = 0; i < numberOfCharacters; i++) {
      password +=
        allCharacters[Math.floor(Math.random() * allCharacters.length)];
    }
    setPassword(password);
  };

  const copyPassword = () => {
    let copyText = document.getElementById("password");

    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-md-center align-items-center vh-100">
        <div style={generatePasswordStyle}>
          <h3 style={{ textAlign: "center" }}>Generate Secure Password</h3>
          <br />
          <InputGroup>
            <FormControl id="password" value={password} onChange={() => {}} />
            <InputGroup.Append>
              <Button variant="primary" onClick={() => copyPassword()}>
                Copy
              </Button>
            </InputGroup.Append>
          </InputGroup>
          <br />
          <Form>
            <FormCheck.Label>
              <Form.Check
                inline
                type="checkbox"
                checked={withNumbers}
                onChange={(includeNumbers) =>
                  setWithNumbers(includeNumbers.target.checked)
                }
              />
              Include Numbers
            </FormCheck.Label>
            <br />
            <FormCheck.Label>
              <Form.Check
                inline
                type="checkbox"
                checked={withSymbols}
                onChange={(includeSymbols) =>
                  setWithSymbols(includeSymbols.target.checked)
                }
              />
              Include Symbols
              <kbd style={{ backgroundColor: "grey" }}>!@#$%^&amp;*()?-=</kbd>
            </FormCheck.Label>
            <br />
            <FormCheck.Label>
              <Form.Check
                inline
                type="checkbox"
                checked={withUppercase}
                onChange={(includeUppercase) =>
                  setWithUppercase(includeUppercase.target.checked)
                }
              />
              Include Uppercase Letters
            </FormCheck.Label>
            <br />
            <br />
            <Form.Label>
              <strong># of Characters: {numberOfCharacters}</strong>
              <Form.Control
                type="range"
                defaultValue={numberOfCharacters}
                custom
                min="1"
                max="128"
                onChange={(numCharacters) =>
                  setNumberOfCharacters(numCharacters.target.value)
                }
              />
            </Form.Label>
            <br /> <br />
            <Button
              style={{ width: "100%" }}
              variant="primary"
              onClick={() => generateSecurePassword()}
            >
              Generate Password
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default PasswordGenerator;
