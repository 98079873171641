import { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import GameOfThronesData from "../data/GameOfThronesData";

function calculateAverage() {
  this.series.forEach((series) => {
    const data = series.data
      .filter((point) => point.isInside)
      .map((point) => point.y);
    const statistics =
      parseFloat((data.reduce((a, b) => a + b, 0) / data.length).toFixed(2)) ||
      0.0;
    const legendItem = series.legendItem;
    const text = legendItem.textStr.replace(/0\.0/g, () => statistics);
    legendItem.attr({
      text,
    });
  });
}

const GameOfThrones = () => {
  const [episodeTitle, setEpisodeTitle] = useState("");
  const [episodeDescription, setEpisodeDescription] = useState("");
  const [episodeSeason, setEpisodeSeason] = useState("");
  const [episodeLink, setEpisodeLink] = useState("");
  const options = {
    chart: {
      zoomType: "x",
      events: {
        load: calculateAverage,
        redraw: calculateAverage,
      },
    },
    title: {
      text: "Game of Thrones Episode Ratings",
    },
    tooltip: {
      formatter: function () {
        const json = GameOfThronesData;
        const seasonNumber = this.series.index + 1;
        const episodeNumber = this.x;
        const myRating = this.y;
        const seasonFormat = `S${seasonNumber}E${episodeNumber}`;
        const episode = json.episodes.filter((e) => {
          return e.seasonNum === seasonNumber && e.episodeNum === episodeNumber;
        });
        let a_an = "";
        if (myRating === 8) a_an = " an <b>";
        else a_an = " a <b>";

        setEpisodeTitle(episode[0].episodeTitle);
        setEpisodeDescription(episode[0].episodeDescription);
        setEpisodeSeason(seasonFormat);
        setEpisodeLink(episode[0].episodeLink);

        const imdbRating = episode[0].imdbRating;

        let difference = Math.round((myRating - imdbRating) * 10) / 10;
        if (difference > 0)
          difference = `<span style="color:green">+${difference}</span>`;
        else if (difference < 0)
          difference = `<span style="color:red">${difference}</span>`;
        const rating = `IMDB Rating: ${imdbRating} <br> Difference: ${difference}`;

        return `"${episode[0].episodeTitle}"
            <br>${seasonFormat} gets ${a_an} ${myRating} 
            </b><br>
          ${rating}`;
      },
    },
    xAxis: {
      title: {
        text: "Episode in Season",
      },
      tickInterval: 1,
      min: 1,
      max: 10,
    },
    yAxis: {
      title: {
        text: "Scale of 1 to 10",
      },
      tickInterval: 1,
      min: 1,
      max: 10,
    },
    legend: {
      enabled: true,
      labelFormatter: function () {
        return `${this.name} <br>Average: 0.0`;
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: true,
        },
        point: {
          events: {
            click: () => {
              window.open(
                `https://www.imdb.com${episodeLink}`,
                "_blank",
                "noopener,noreferrer"
              );
            },
          },
        },
        pointStart: 1,
      },
    },
    series: [
      {
        name: "Season 1",
        data: [6, 7, 5, 7, 7, 7, 7, 8, 8, 9],
      },
      {
        name: "Season 2",
        data: [6, 6, 6, 8, 7, 9, 7, 5, 8, 9],
      },
      {
        name: "Season 3",
        data: [6, 6, 7, 8, 8, 8, 8, 7, 10, 8],
      },
      {
        name: "Season 4",
        data: [6, 8, 7, 8, 8, 9, 7, 8, 9, 9],
      },
      {
        name: "Season 5",
        data: [7, 8, 8, 8, 7, 9, 7, 9, 9, 8],
      },
      {
        name: "Season 6",
        data: [8, 9, 8, 7, 9, 8, 7, 8, 9, 8],
      },
      {
        name: "Season 7",
        data: [7, 8, 8, 9, 7, 9, 9],
      },
      {
        name: "Season 8",
        data: [7, 8, 9, 8, 9, 7],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
    credits: {
      enabled: false,
    },
  };

  return (
    <>
      <div
        id="container"
        style={{ width: "600px", height: "400px", margin: "0 auto" }}
      >
        <HighchartsReact highcharts={Highcharts} options={options} />
        {episodeSeason && (
          <>
            <h3>
              {episodeSeason}: "{episodeTitle}"
            </h3>
            {episodeDescription}
          </>
        )}
      </div>
    </>
  );
};
export default GameOfThrones;
