import { useState, useEffect } from "react";

const Draw = () => {
  let [color, setColor] = useState("#000000");
  let [opacity, setOpacity] = useState(0.99);
  let [brushSize, setBrushSize] = useState(10);
  let [points, setPoints] = useState([]);
  let [layer, setLayer] = useState(0);
  let undo = () => {
    let l = layer;

    const draft = document.getElementById("draft");
    const main = document.getElementById("main");
    const ctx = draft.getContext("2d");
    const mctx = main.getContext("2d");

    const currentColor = ctx.strokeStyle;
    const currentOpacity = draft.style.opacity;
    const currentBrushSize = ctx.brushSize;

    ctx.clearRect(0, 0, draft.width, draft.height);
    mctx.clearRect(0, 0, draft.width, draft.height);
    setPoints([]);
    for (let k = 0; k < layer; k++) {
      let temp = points.filter((p) => p.layer === k);
      let prevPoint = temp[0];
      for (let i = 0; i < temp.length; i++) {
        if (i > 0) {
          let currentPoint = temp[i];
          ctx.lineCap = "round";
          ctx.strokeStyle = currentPoint.color;
          draft.style.opacity = currentPoint.opacity;
          mctx.globalAlpha = currentPoint.opacity;
          ctx.lineWidth = currentPoint.brushSize;
          ctx.beginPath();
          ctx.moveTo(prevPoint.x, prevPoint.y);

          ctx.lineTo(currentPoint.x, currentPoint.y);
          ctx.stroke();
          currentPoint.layer = k;
          setPoints((allTemp) => [...allTemp, currentPoint]);

          prevPoint = temp[i];
        }
      }
    }
    l -= 1;
    if (l >= 0) setLayer(l);
    ctx.strokeStyle = currentColor;
    draft.style.opacity = currentOpacity;
    mctx.globalAlpha = currentOpacity;
    ctx.lineWidth = currentBrushSize;
  };
  useEffect((brushSize, color, opacity) => {
    const draft = document.getElementById("draft");
    const main = document.getElementById("main");

    draft.height = window.innerHeight - 50;
    draft.width = window.innerWidth;

    main.height = window.innerHeight - 50;
    main.width = window.innerWidth;

    const ctx = draft.getContext("2d");
    const mctx = main.getContext("2d");

    let alpha = opacity;

    ctx.strokeStyle = color;
    ctx.lineWidth = brushSize;

    draft.style.opacity = alpha;
    mctx.globalAlpha = alpha;

    let painting = false;
    let prev = { x: 0, y: 0 };
    const mouseup = () => {
      setLayer(layer++);
      painting = false;
      mctx.beginPath();
      mctx.drawImage(draft, 0, 0);
      ctx.clearRect(0, 0, draft.width, draft.height);
    };

    const mousedown = (b) => {
      painting = true;
      const pos = b.type.startsWith("mouse")
        ? getMousePos(draft, b)
        : getFingerPos(draft, b);
      prev = pos;
    };

    const mousemove = (b) => {
      if (!painting) return;
      const pos = b.type.startsWith("mouse")
        ? getMousePos(draft, b)
        : getFingerPos(draft, b);
      ctx.lineCap = "round";
      ctx.beginPath();
      ctx.moveTo(prev.x, prev.y);
      ctx.lineTo(pos.x, pos.y);
      ctx.stroke();
      setPoints((points) => [...points, pos]);
      prev = pos;
    };

    const getMousePos = (canvas, evt) => {
      const rect = canvas.getBoundingClientRect();
      return {
        x: evt.clientX - rect.left,
        y: evt.clientY - rect.top,
        layer,
        color: ctx.strokeStyle,
        opacity: draft.style.opacity,
        brushSize: ctx.lineWidth,
      };
    };

    const getFingerPos = (canvas, evt) => {
      evt.preventDefault();
      const rect = canvas.getBoundingClientRect();
      return {
        x: evt.touches[0].clientX - rect.left,
        y: evt.touches[0].clientY - rect.top,
        layer,
        color: ctx.strokeStyle,
        opacity: draft.style.opacity,
        brushSize: ctx.lineWidth,
      };
    };

    const clear = document.getElementById("clear");

    clear.addEventListener("click", () => {
      ctx.clearRect(0, 0, draft.width, draft.height);
      mctx.clearRect(0, 0, draft.width, draft.height);
      setPoints([]);
      setLayer(0);
    });

    draft.addEventListener("mousedown", mousedown);
    draft.addEventListener("mouseup", mouseup);
    draft.addEventListener("mousemove", mousemove);

    draft.addEventListener("touchstart", mousedown);
    draft.addEventListener("touchend", mouseup);
    draft.addEventListener("touchmove", mousemove);
  }, []);

  useEffect(() => {
    const draft = document.getElementById("draft");
    const ctx = draft.getContext("2d");
    ctx.strokeStyle = color;
  }, [color]);

  useEffect(() => {
    const draft = document.getElementById("draft");
    const main = document.getElementById("main");
    const mctx = main.getContext("2d");
    draft.style.opacity = opacity;
    mctx.globalAlpha = opacity;
  }, [opacity]);

  useEffect(() => {
    const draft = document.getElementById("draft");
    const ctx = draft.getContext("2d");
    ctx.lineWidth = brushSize;
  }, [brushSize]);

  return (
    <div
      className="container-fluid"
      style={{
        overflowX: "hidden",
        margin: 0,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div className="d-flex justify-content-center">
        <div style={{ position: "relative" }}>
          <canvas
            style={{
              border: "1px solid",
              position: "absolute",
            }}
            id="main"
          ></canvas>
          <canvas
            style={{
              border: "1px solid",
            }}
            id="draft"
          ></canvas>
        </div>
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ height: "40px", touchAction: "none" }}
      >
        <div style={{ paddingRight: "5px" }}>
          <div>Brush Size: {brushSize}</div>
          <input
            type="range"
            className="form-range"
            min="1"
            max="50"
            defaultValue={brushSize}
            onChange={(e) => setBrushSize(e.target.value)}
            style={{ width: "100px" }}
          ></input>
        </div>
        <div style={{ paddingRight: "5px" }}>
          <div>Opacity: {parseInt(opacity * 100) + 1}</div>
          <input
            type="range"
            className="form-range"
            min="0"
            max="99"
            defaultValue="99"
            onChange={(e) => setOpacity(e.target.value / 100)}
            style={{ width: "100px" }}
          ></input>
        </div>

        <input
          type="color"
          onChange={(c) => setColor(c.target.value)}
          value={color}
          style={{ width: "40px", height: "40px" }}
        ></input>

        <button
          className="btn btn-sm btn-danger"
          style={{ position: "absolute", top: 10, left: 10 }}
          id="clear"
        >
          Clear
        </button>

        <button
          className="btn btn-sm btn-primary"
          style={{ position: "absolute", top: 10, right: 10 }}
          id="undo"
          onClick={() => undo()}
        >
          Undo
        </button>

        {/* <button
          className="btn btn-sm-p5 btn-info"
          id="debug"
          onClick={() => console.log(points)}
        >
          Debug
        </button> */}
      </div>
    </div>
  );
};

export default Draw;
