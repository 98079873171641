import { Link } from "react-router-dom";

const errorMessages = [
  "Alas...there doesn't seem to be anything here.",
  "Oops. Something went wrong.",
  "This page isn't valid.",
  "I couldn't find what you were looking for.",
  "It doesn't look like this page exists.",
  "There's nothing to see here.",
  "Hmm, how'd you end up here?",
  `Oh no, this page, it doesn't exist.`,
];

const NotFound = () => (
  <div
    className="container"
    style={{
      textAlign: "center",
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
    }}
  >
    <h1 style={{ fontSize: "8em" }}>404</h1>
    <h3>{errorMessages[Math.floor(Math.random() * errorMessages.length)]}</h3>
    <p>
      <strong>{window.location.pathname}</strong> is not a valid page.
    </p>

    <Link to="/">
      <button className="btn btn-primary">
        Return to <strong>andrewghaly.com</strong>
      </button>
    </Link>
  </div>
);

export default NotFound;
