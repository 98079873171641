import { Link } from "react-router-dom";

const Albers = () => (
  <div className="container-fluid" style={{ marginTop: "15px" }}>
    <figure>
      <svg
        id="albers"
        width="501"
        height="374"
        style={{ backgroundColor: "black" }}
      >
        <polygon
          id="center"
          title="center"
          points="17, 127, 460, 127, 460, 314, 441, 314, 441, 169, 319, 169, 319, 295, 157, 295, 157, 169, 61, 169, 61, 314, 17, 314"
          style={{ fill: "#323755" }}
        />
        <polygon
          id="right"
          title="right"
          points="460, 127, 501, 127, 501, 374, 441, 374, 441, 314, 460, 314"
          style={{ fill: "#883733" }}
        />
        <polygon
          id="mid"
          title="mid"
          points="61, 169, 157, 169, 157, 295, 319, 295, 319, 169, 441, 169, 441, 314, 61, 314"
          style={{ fill: "#5c3d45" }}
        />
        <polygon
          id="left"
          title="left"
          points="17, 127, 0, 127, 0, 374, 61, 374, 61, 314, 17, 314"
          style={{ fill: "#883733" }}
        />
        <polygon
          id="top-mid"
          title="top-mid"
          points="17, 127, 0, 127, 0, 40, 501, 40, 501, 127, 460, 127, 460, 86, 17, 86"
          style={{ fill: "#764348" }}
        />
        <polygon
          id="top"
          title="top"
          points="0, 0, 501, 0, 501, 40, 0, 40"
          style={{ fill: "#883733" }}
        />
        <polygon
          id="top-bottom"
          title="top-bottom"
          points="17, 86, 460, 86, 460, 127, 17, 127"
          style={{ fill: " #5c3d45" }}
        />
        <polygon
          id="bottom"
          title="bottom"
          points="61, 314, 441, 314, 441, 374, 61, 374"
          style={{ fill: "#764348" }}
        />
        <polygon
          id="l-rect"
          title="l-rect"
          points="95, 210, 135, 210, 135, 295, 95, 295"
          style={{ fill: "#764348" }}
        />
        <polygon
          id="r-rect"
          title="r-rect"
          points="360, 210, 405, 210, 405, 295, 360, 295"
          style={{ fill: "#764348" }}
        />
      </svg>
      <figcaption>
        <strong>Josef Albers</strong>
        <br />
        <i>Three Reds Around Blue</i>
        <br />
        1948
      </figcaption>
    </figure>
    <br />
    <div style={{ width: "500px" }}>
      The above SVG is a homage to Josef Alber's{" "}
      <a href="https://collections.mfa.org/objects/33965">
        <i>Three Reds Around Blue</i>
      </a>
      . The SVG is composed of 10 distinct polygons, there is no overlap amongst
      the polygons save for the 2 "floating" rectangles (which are both 100%
      opacity). Feel free to inspect the source and see for yourself.{" "}
      <Link to="/color">Try this effect here</Link>
    </div>
  </div>
);

export default Albers;
