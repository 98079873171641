const ResumeSection = ({ sectionTitle, children }) => (
  <div className="row">
    <div className="col-12 col-md-4">
      <h4>{sectionTitle}</h4>
    </div>
    <div className="col-12 col-md-8">
      <hr></hr>
      {children}
    </div>
  </div>
);

export default ResumeSection;
