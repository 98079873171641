import { useState } from "react";
import { Link } from "react-router-dom";

const Color = () => {
  const [colorA, setColorA] = useState("#3eb3ec");
  const [colorB, setColorB] = useState("#5962a3");
  const [colorC, setColorC] = useState("#e8001f");

  const changeColor = (color, layerId) => {
    if (layerId === "colorA") {
      setColorA(color);
    } else if (layerId === "colorB") {
      setColorB(color);
    } else if (layerId === "colorC") {
      setColorC(color);
    }
  };

  const swapColors = () => {
    setColorA(colorC);
    setColorC(colorA);
  };

  return (
    <div id="container" style={{ margin: "10px" }}>
      <svg id="blocks" width="180" height="180">
        <polygon
          id="layer_a"
          points="0, 0, 100,0, 100,50, 50,50, 50,100,0,100"
          style={{ fill: colorA }}
        ></polygon>
        <polygon
          id="layer_b"
          points="50,50,100,50,100,100,50,100"
          style={{ fill: colorB }}
        ></polygon>
        <polygon
          id="layer_c"
          points="100, 50, 150, 50, 150, 150, 50,150,50,100,100,100"
          style={{ fill: colorC }}
        ></polygon>
      </svg>
      <hr></hr>
      <input
        type="color"
        id="colorA"
        onChange={(c) => changeColor(c.target.value, c.target.id)}
        value={colorA}
        style={{ width: "40px", height: "40px" }}
      ></input>
      <input
        type="color"
        id="colorB"
        onChange={(c) => changeColor(c.target.value, c.target.id)}
        value={colorB}
        style={{ width: "40px", height: "40px" }}
      ></input>
      <input
        type="color"
        id="colorC"
        onChange={(c) => changeColor(c.target.value, c.target.id)}
        value={colorC}
        style={{ width: "40px", height: "40px" }}
      ></input>
      <input type="button" onClick={swapColors} value="Swap"></input>
      <br />
      <div style={{ width: "500px", marginTop: "10px" }}>
        <i>Three Polygons</i> interactive art. Swap the colors to see how they
        interact. What is the point at which one color "overlays" the other?
        <p style={{ marginTop: "10px" }}>
          Inspired by <Link to="/albers">Josef Albers</Link>
        </p>
      </div>
    </div>
  );
};

export default Color;
