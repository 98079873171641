import { Link } from "react-router-dom";
import ResumeHeader from "./resumeHeader";
import ResumeSection from "./resumeSection";
import awsCert from "../../images/aws_cert_icon.png";
const Resume = () => (
  <div className="container" style={{ maxWidth: "1100px" }}>
    <ResumeHeader />
    <ResumeSection sectionTitle="Technical Skills">
      <p>
        <i>
          <u>Languages &amp; Libraries:</u>{" "}
        </i>
        C#, Python, JavaScript ES6, React, jQuery, MSSQL, HTML, XAML, CSS, XML
      </p>
      <p>
        <i>
          <u>Technologies &amp; Frameworks:</u>{" "}
        </i>
        .NET (WPF, WCF, Entity Framework), HPC, ASP.NET, Agile, Scrum, RESTFul
        APIs, Flask, AJAX, Bootstrap, Highcharts, MSTest, Gherkin, Specflow,
        Enzyme, Jest, AWS, MVVM, MVC
      </p>
      <p>
        <i>
          <u>Software:</u>{" "}
        </i>
        Visual Studio, Visual Studio Code, MSSQL Studio, TFS (Azure DevOps),
        Git, VMware, VirtualBox, Illustrator, Photoshop
      </p>
    </ResumeSection>
    <ResumeSection sectionTitle="Projects">
      <p>
        <Link to="/password">Secure Password Generator</Link> - Create complex
        passwords (works offline)
      </p>
      <p>
        <Link to="/draw">Web Painting App</Link> - Paint pictures in your
        browser, works on desktop and mobile
      </p>
      <p>
        <Link to="/got">GoT</Link> - My personal GoT ratings
      </p>
    </ResumeSection>
    <ResumeSection sectionTitle="Certifications">
      <table>
        <tbody>
          <tr>
            <td style={{ textAlign: "center" }}>
              <img
                style={{ height: "50px" }}
                alt="AWS Certified Icon"
                src={awsCert}
              ></img>
            </td>
            <td>
              <a href="https://www.credly.com/badges/802acd51-ef52-4b73-9e1c-792be9339874/public_url">
                AWS Certified Solutions Architect – Associate
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </ResumeSection>
  </div>
);

export default Resume;
